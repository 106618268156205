.background-img {
  background-image: url('../../assets/landing.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-size: cover;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: white;
}

.background-img-one {
  background-image: url('../../assets/bg.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vw;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background-color: transparent;
}
